.App {
    text-align: center;

}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

.logo {
    max-width: 200px;
    margin: auto;
    display: block;
}


.fade-in {
    animation: fade-in 1s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

.teaser {
    color: #00407F !important;
    border-color: #00407F !important;
}